<template>
  <div class="" id="results">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row p-2">
        <div class="col-12" v-html="pageContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import Preloader from "../components/Preloader";
  export default {
    name: "Results",
    components:{
      Preloader,
    },
    data(){
      return {
        loaded:false,
        pageContent:null
      }
    },
    created() {
      this.$http.get("get-results").then(function (response) {
        if (response.body.status){
          this.pageContent = response.body.page.content;
        }
        this.loaded=true;
      })
    }
  }
</script>

<style scoped>

</style>